







































































































import { Component, Emit, Vue } from 'vue-property-decorator';
import { fromJson } from '@/utils/helpers';
import charAt from '@/filters/charAt';

@Component({
  filters: {
    charAt,
  },
})
export default class NavBar extends Vue {
  menu: boolean = false;
  drawer: boolean = false;
  fullName!: string;
  email!: string;

  @Emit('drawer')
  openDrawer() {
    this.drawer = !this.drawer;
    return this.drawer;
  }

  @Emit('logout')
  signOut() {
    return null;
  }

  created() {
    this.fullName = fromJson(localStorage.getItem('current.session.user'))?.fullname;
    this.email = fromJson(localStorage.getItem('current.session.user'))?.email;
  }
}
